<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('add_laundry_subscription') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('pos_shifts.name') }}</label>
                <input v-model="data.name" :class="validation && validation.name ? 'is-invalid' : ''"
                       class="form-control"
                       type="text"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('max_customers') }}</label>
                <input v-model="data.max_customers" :class="validation && validation.max_customers ? 'is-invalid' : ''"
                       class="form-control"
                       type="number"/>
                <span v-if="validation && validation.max_customers"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.max_customers[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('currency') }}</label>
                <select id="currency" v-model="data.currency_id" class="custom-select" name="">
                  <option v-for="row in currencies" :key="row.id" :value="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.currency_id[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('categories.price') }}</label>
                <input v-model="data.price" :class="validation && validation.price ? 'is-invalid' : ''"
                       class="form-control"
                       type="number"/>
                <span v-if="validation && validation.price"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.price[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_invoices.start_date') }}</label>
                <input v-model="data.start_date" :class="validation && validation.start_date ? 'is-invalid' : ''"
                       class="form-control"
                       type="date"/>
                <span v-if="validation && validation.start_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.start_date[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('sales_invoices.end_date') }}</label>
                <input v-model="data.end_date" :class="validation && validation.end_date ? 'is-invalid' : ''"
                       class="form-control"
                       type="date"/>
                <span v-if="validation && validation.end_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.start_date[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('descriptions') }}</label>
                <textarea v-model="data.descriptions"
                          :class="  validation && validation.descriptions ? 'is-invalid' : ''" class="form-control"
                          rows="4"
                          type="text"></textarea>
                <span v-if="validation && validation.descriptions"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.descriptions[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('customers_feature.status') }}</label>
                <b-form-checkbox :key="'a-1'" v-model="data.is_active" :name="'check-button-min'" size="lg"
                                 switch></b-form-checkbox>

                <span v-if="validation && validation.is_active" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.is_active[0] }}
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>


      <div v-if="canShoTable" class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('categories.services') }}</h6>
            <button class="btn btn-primary mr-2" type="button" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
              <thead>
              <tr>
                <th>
                  <span>{{ $t('categories.service') }}</span>
                </th>
                <th width="15%">{{ $t('times_no') }}</th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ $t('purchases_invoices.items') }}
                    </span>
                  </div>
                </th>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ $t('categories.categories') }}
                    </span>
                  </div>
                </th>
                <th class="text-center" width="20px">
                  <span>{{ $t('actions') }}</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(service, index) in service_list" :key="index">
                <td>
                  <select id="service" v-model="service.service.id" class="custom-select"
                          name="" @change="setItemsDependOnService($event,index,true)">
                    <option v-for="row in laundry_services_option" :key="row.id" :value="row.id">{{ row.name }}</option>
                  </select>
                </td>
                <td>
                  <input v-model="service.times" :placeholder="$t('times_no')"
                         class="form-control"
                         type="number"/>
                </td>
                <td>
                  <multiselect v-model="service.items_object"
                               :multiple="true"
                               :options="service.items_option"
                               :placeholder="$t('purchases_invoices.items')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="true"
                               label="name"
                               track-by="id"
                               @input="getItemsIds(index)"
                               @select="resetCategories(index)"
                  >
                  </multiselect>
                </td>
                <td>
                  <!-- category multiselect  -->
                  <multiselect v-model="service.categories_object"
                               :multiple="true"
                               :options="categories_option"
                               :placeholder="$t('categories.categories')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="true"
                               label="name"
                               track-by="id"
                               @input="getCategoriesIds(index)"
                               @select="resetItems(index)"
                  >
                  </multiselect>
                </td>

                <td class="text-center">
                  <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-customers-features",
  data() {
    return {
      mainRoute: 'pos/laundry_subscriptions',
      subMainRoute: 'customers/customer-feature',
      mainRouteDependency: 'base/dependency',
      items_option: [],
      laundry_services_option: [],
      currencies: [],
      categories_option: [],
      idEdit: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      validation: null,
      canShoTable: false,
      data: {
        name: null,
        max_customers: null,
        currency_id: null,
        price: null,
        start_date: null,
        end_date: null,
        is_active: true,
        descriptions: null,
      },
      setItemsDependOnServiceReCallCount: 0,
      repeater_service: {
        id: null,
        times: null,
        service: {id: null},
        items: null,
        items_object: [],
        items_option: [],
        categories: [],
        categories_object: []
      },
      service_list: []
    };
  },
  watch: {},

  methods: {
    save() {
      this.submit()
    },
    setItemsDependOnService(event, idx, resetItems) {
      resetItems && this.$set(this.service_list, idx, {...this.service_list[idx], items_object: []});

      if (this.items_option.length) {
        const items = this.items_option.filter(item => item.service_ids.includes(parseInt(event.target.value)));
        this.$set(this.service_list, idx, {...this.service_list[idx], items_option: items});
        return;
      }

      if (this.setItemsDependOnServiceReCallCount < 10) {
        setTimeout(() => {
          this.setItemsDependOnService(event, idx);
          this.setItemsDependOnServiceReCallCount++;
        }, 1000);
      }
    },
    getItemsIds(idx) {
      this.service_list[idx].items = this.service_list[idx].items_object.map((row) => {
        return row.id;
      });
    },
    getCategoriesIds(idx) {
      this.service_list[idx].categories = this.service_list[idx].categories_object.map((row) => {
        return row.id;
      });
    },
    submit() {
      let $url = this.idEdit ? this.mainRoute + '/' + this.idEdit : this.mainRoute;
      let data = this.data
      let service_list = this.service_list;
      let $method = this.isEditing ? 'put' : 'post';
      if (this.isEditing) {
        delete data.currency_name;
      }
      service_list = service_list.map((row) => {
        delete row.items_option;
        delete row.categories_object;
        return row;
      });
      ApiService[$method]($url, {
        ...data,
        service_list: service_list,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/laundry-subscriptions');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    async getData() {
      await ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        if (response.data.data) {
          this.data = response.data.data;
          if (!response.data.data.service_list) {
            this.service_list = [];
            this.addToRepeater();
          } else if (response.data.data.service_list && response.data.data.service_list.length <= 0) {
            this.service_list = [];
            this.addToRepeater();
          } else {
            this.service_list = response.data.data.service_list;
            this.service_list.forEach((row, index) => {
              this.$set(this.service_list, index, {
                ...row,
                items_option: [{id: null, name: this.$t('no_items_found'),}]
              });
            });
          }
        } else {
          this.service_list = [];
          this.addToRepeater();
        }
      }).then(() => {
        this.service_list.forEach((row, index) => {
          this.setItemsDependOnService({target: {value: row.service.id}}, index, false);
          if (row.categories && row.categories.length) {
            this.getCategoriesObject(row.categories, index);
          }
        });

        this.canShoTable = true;
      });
    },
    resetItems(idx) {
      this.$set(this.service_list, idx, {...this.service_list[idx], items_object: [], items: null});
    },
    resetCategories(idx) {
      this.$set(this.service_list, idx, {...this.service_list[idx], categories_object: [], categories: []});
    },
    getCategoriesObject(categories, idx) {
      if (this.categories_option.length) {
        let categories_object = this.categories_option.filter((row) => {
          return categories.includes(row.id);
        });
        this.$set(this.service_list, idx, {...this.service_list[idx], categories_object: categories_object});
      } else {
        setTimeout(() => {
          this.getCategoriesObject(categories, idx);
        }, 1000);
      }
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories?type=1").then((response) => {
        this.categories_option = response.data.data.map((row) => {
          return {id: row.id, name: row.label};
        });
      });
    },
    getLaundryServices() {
      ApiService.get(this.mainRouteDependency + "/laundry_services").then((response) => {
        this.laundry_services_option = response.data.data.map((row) => {
          return {id: row.id, name: row.name};
        });
      });
    },
    getItems() {
      ApiService.get(this.mainRouteDependency + "/items_with_services").then((response) => {
        let res = response.data.data.map((row) => {
          let service_ids = row.services.map((service) => {
            return service;
          });
          return service_ids.length > 0 ? {id: row.id, name: row.name, service_ids: service_ids} : null;
        });
        this.items_option = res.filter((row) => {
          return row !== null;
        });
      });
    },
    addToRepeater() {
      this.service_list.unshift(this.repeater_service);
      this.repeater_service = {
        id: null,
        times: null,
        service: {id: null},
        items: null,
        items_object: [],
        items_option: [],
        categories: [],
        categories_object: []
      };
    },
    deleteFromRepeater(index) {
      this.service_list.splice(index, 1);
    },
    changeStatus(id, status) {
      if (id) {
        ApiService.patch(this.subMainRoute + '/change-status/' + id, {
          status: (status ? '1' : '0'),
        }).then(response => {
          this.$successAlert(response.data.message);
        }).catch(error => {
          this.$errorAlert(error);
        });
      }

    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.laundry_subscriptions")}]);
    this.addToRepeater();
    if (this.idEdit) {
      this.getData();
    }
  },
  created() {
    this.getCurrencies();
    this.getItems();
    this.getLaundryServices();
    this.getCategories();
    if (!this.idEdit) {
      this.canShoTable = true;
      this.defaultDataForUser();
    }
  }
};
</script>


